@use "sass:string";

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap");

@import "./_App.module";

:root {
  --constantFooterHeight: #{$constantFooterHeight};
  --constantHeaderHeight: #{$constantHeaderHeight};
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100% !important;
  width: 100vw !important;
  font-family: "Roboto", sans-serif;
  font-size: 100%;
}
* {
  box-sizing: border-box;
}

body {
  background-color: $app-background-color;
  overflow: hidden;
}

.span_bold {
  font-weight: bold;
}
.span_italic {
  font-style: italic;
}
.span_maroon {
  color: $custom-secondary-color;
}
.span_success {
  color: $success-color;
}
.span_danger {
  color: $danger-color;
}
.span_primary {
  color: $primary-color;
}

//* Info, initial modal styles
.modal-content {
  background-color: $app-background-color;
}

// Bus icons
.bus_icon {
  background-color: transparent;
}

//* OSM Tiles -> grayscale
.leaflet-grayscale {
  .leaflet-tile-loaded {
    filter: grayscale(80%) brightness(106%) saturate(50%);
  }
}

// Location button
button.location-button {
  padding: 0 !important;
  display: flex;
  justify-content: "center";
  align-items: "center";
  align-content: "center";
}

// Fixing Ant Design Select
.ant-select-dropdown {
  z-index: 1099 !important;
}

//* React-Bootstrap progress bar colors
.progress-bar.bg-success.progress-bar-animated.progress-bar-striped {
  background-color: $success-color !important;
}
.progress-bar.bg-warning.progress-bar-animated.progress-bar-striped {
  background-color: $warning-color !important;
}
.progress-bar.bg-danger.progress-bar-animated.progress-bar-striped {
  background-color: $danger-color !important;
}

.card-shadow {
  box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.5);
}

//* Customizing react-bootstrap switch - vehicle inspection
div.list-group-item {
  padding: 0.5rem;
  form {
    div.vehicle-inspection {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      margin-bottom: 0;
      min-height: 1.5rem;
      gap: 6px;
      input {
        margin-top: 0;
        &:checked {
          background-color: $success-color;
          border-color: $success-color;
        }
        min-width: 1rem;
        min-height: 1rem;
      }
      label {
        font-size: 0.85rem;
        line-height: 1.5rem;
        vertical-align: middle;
        white-space: nowrap;
      }
    }
    div.inspection-required {
      input {
        background-color: $danger-color;
        border-color: $danger-color;
        outline: 2px solid $warning-color;
      }
    }
  }
}

//* eSignature Canvas
canvas.signatureCanvas {
  border: 1px solid black;
  width: 100%;
  min-height: 400px;
}

//* Compass on the map
div.leaflet-top.leaflet-left {
  div {
    border: none !important;
  }
  div.leaflet-control-rotate {
    border: none;
    background-color: transparent !important;
    pointer-events: none !important;
    touch-action: none !important;
    width: 40px;
    height: 40px;
    a.leaflet-control-rotate-toggle {
      background-color: transparent !important;
      width: 100% !important;
      height: 100% !important;
      span {
        width: 100%;
        height: 100%;
        background-color: transparent !important;
        background-image: url("./assets/icons/compass.svg") !important;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}
