//* Variables - colors
$custom-secondary-color: #92253d;
$text-color: #444;
$app-background-color: lightgray;
$app-background-whiteSmoke: whitesmoke;
$dark-color: #212529;
$black-color: black;

$primary-color: #0d6efd;
$success-color: #198754;
$danger-color: #dc3545;
$warning-color: #f6be00;
$secondary-color: #6c757d;
$info-color: #0dcaf0;
$indigo-color: #6610f2;
$purple-color: #6f42c1;
$pink-color: #d63384;
$teal-color: #20c997;
$orange-color: #fd7e14;

//* Other variables
$constantFooterHeight: 144px;
$constantHeaderHeight: 56px;

:export {
  customSecondaryColor: $custom-secondary-color;
  textColor: $text-color;
  appBackgroundColor: $app-background-color;
  appBackgroundColorWhiteSmoke: $app-background-whiteSmoke;
  successColor: $success-color;
  dangerColor: $danger-color;
  warningColor: $warning-color;
  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  darkColor: $dark-color;
  constantFooterHeight: $constantFooterHeight;
  constantHeaderHeight: $constantHeaderHeight;
  blackColor: $black-color;
  infoColor: $info-color;
  indigoColor: $indigo-color;
  purpleColor: $purple-color;
  pinkColor: $pink-color;
  tealColor: $teal-color;
  orangeColor: $orange-color;
}

//* SVG color format change if HEX format
@function urlFriendlyColor($color) {
  @return "%23" + string.slice("#{$color}", 2, -1);
}
